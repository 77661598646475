@font-face {
    font-family: "manonegra";
    src: url("./fonts/WCManoNegraBta.woff2") format("woff2"), url("./fonts/WCManoNegraBta.woff") format("woff"),
        url("./fonts/WCManoNegraBta.svg#WCManoNegraBta") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "kristen";
    src: url("./fonts/KristenITC-Regular.woff2") format("woff2"), url("./fonts/KristenITC-Regular.woff") format("woff"),
        url("./fonts/KristenITC-Regular.svg#KristenITC-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "segoe";
    src: url("./fonts/SegoeScript.woff2") format("woff2"), url("./fonts/SegoeScript.woff") format("woff"),
        url("./fonts/SegoeScript.svg#SegoeScript") format("svg");
    font-weight: normal;
    font-style: normal;
}
