body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans";
    background-color: #eee;
}

@font-face {
    font-family: "manonegra";
    src: url("fonts/WCManoNegraBta.woff2") format("woff2"), url("fonts/WCManoNegraBta.woff") format("woff"),
        url("fonts/WCManoNegraBta.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "kristen";
    src: url("fonts/KristenITC-Regular.woff2") format("woff2"), url("fonts/KristenITC-Regular.woff") format("woff"),
        url("fonts/KristenITC-Regular.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "segoe";
    src: url("fonts/SegoeScript.woff2") format("woff2"), url("fonts/SegoeScript.woff") format("woff"),
        url("fonts/SegoeScript.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    font-weight: 700;
    font-style: bold;
    src: url("fonts/OpenSans-Bold.ttf");
}

@font-face {
    font-family: "Open Sans";
    font-weight: 700;
    font-style: italic;
    src: url("fonts/OpenSans-BoldItalic.ttf");
}

@font-face {
    font-family: "Open Sans";
    font-weight: 400;
    font-style: normal;
    src: url("fonts/OpenSans-Regular.ttf");
}

@font-face {
    font-family: "Open Sans";
    font-weight: 400;
    font-style: italic;
    src: url("fonts/OpenSans-Italic.ttf");
}

@font-face {
    font-family: "Open Sans";
    font-weight: 200;
    font-style: normal;
    src: url("fonts/OpenSans-Light.ttf");
}

@font-face {
    font-family: "Open Sans";
    font-weight: 200;
    font-style: italic;
    src: url("fonts/OpenSans-LightItalic.ttf");
}

@font-face {
    font-family: "Open Sans";
    font-weight: 550;
    font-style: Normal;
    src: url("fonts/OpenSans-Semibold.ttf");
}

@font-face {
    font-family: "Open Sans";
    font-weight: 550;
    font-style: Italic;
    src: url("fonts/OpenSans-SemiboldItalic.ttf");
}

@font-face {
    font-family: "Open Sans";
    font-weight: 900;
    font-style: Bold;
    src: url("fonts/OpenSans-ExtraBold.ttf");
}

@font-face {
    font-family: "Open Sans";
    font-weight: 900;
    font-style: Italic;
    src: url("fonts/OpenSans-ExtraBoldItalic.ttf");
}
